/* eslint  react/prop-types: off */
import empty from "empty";
import { map, orderBy } from "lodash/fp";
import DropDownMenu from "material-ui/DropDownMenu";
import IconButton from "material-ui/IconButton";
import IconMenu from "material-ui/IconMenu";
import MenuItem from "material-ui/MenuItem";
import RaisedButton from "material-ui/RaisedButton";
import Subheader from "material-ui/Subheader";
import Delete from "material-ui/svg-icons/action/delete";
import Search from "material-ui/svg-icons/action/search";
import Add from "material-ui/svg-icons/content/add";
import Clear from "material-ui/svg-icons/content/clear";
import Edit from "material-ui/svg-icons/content/create";
import Save from "material-ui/svg-icons/content/save";
import Download from "material-ui/svg-icons/file/file-download";
import Fullscreen from "material-ui/svg-icons/navigation/fullscreen";
import FullscreenExit from "material-ui/svg-icons/navigation/fullscreen-exit";
import MoreVert from "material-ui/svg-icons/navigation/more-vert";
import { TableHeader, TableHeaderColumn, TableRow } from "material-ui/Table";
import TextField from "material-ui/TextField";
import React, { Fragment, useCallback, useState } from "react";
import { compose, setDisplayName, withHandlers, withState } from "recompose";

import { IndexViewVisibility } from "../../business/models";
import useReduxState from "../../business/useReduxState";
import { flown } from "../../lodash";
import { execOnChange } from "../../recompose.contrib";
import { indexFilter } from "../../sagas";
import InlineIconButton from "../material/InlineIconButton";
import { scopeLabel } from "../search/constants";
import { preheader } from "./table";

const KeywordInputComponent = ({
  keyword,
  label,
  handleChange,
  handleSubmit,
}) => (
  <form
    method="post"
    style={{ display: "inline-block" }}
    onSubmit={handleSubmit}
  >
    <TextField
      floatingLabelText={label}
      value={keyword}
      onChange={handleChange}
      autoFocus
    />
  </form>
);

const KeywordInput = compose(
  setDisplayName("KeywordInput"),
  withState("keyword", "update", ({ value }) => value || ""),
  withHandlers({
    handleChange:
      ({ onChange, update }) =>
      (_, value) => {
        update(value);
        onChange(value, false);
      },
    handleSubmit:
      ({ onChange, keyword }) =>
      (event) => {
        event.preventDefault();
        onChange(keyword, true);
      },
    handleUpdate:
      ({ update, value }) =>
      () =>
        update(value),
  }),
  execOnChange("handleUpdate", "value")
)(KeywordInputComponent);

const displayNone = { display: "none" };
const cellClassNames = (properties = empty.array, property) =>
  `${property} one-of-${properties.length}`;

const cellStyle = (properties = empty.array, property) => {
  const titleWidth =
    properties.length < 5 ? 50 : properties.length === 5 ? 40 : 30;

  if (property === "titel") {
    return { width: `${titleWidth}%` };
  }

  const myWidth = Math.round((100 - titleWidth) / (properties.length - 1));
  return { width: `${myWidth}%` };
};

export const emptyView = {
  id: 0,
  own: false,
  name: "",
  visibility: IndexViewVisibility.public,
  filter: "",
  order: { descending: false },
  queryString: "",
};

const Tools = ({ disabled, deleteView, editView, saveView }) => (
  <IconMenu
    iconButtonElement={
      <IconButton disabled={disabled}>
        <MoreVert />
      </IconButton>
    }
    anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
    targetOrigin={{ horizontal: "left", vertical: "top" }}
    title={"Acties met deze weergave"}
  >
    <Subheader>Acties met deze weergave</Subheader>
    <MenuItem primaryText={"Opslaan"} onClick={saveView} rightIcon={<Save />} />
    <MenuItem
      primaryText={"Bewerken"}
      onClick={() => editView(false)}
      rightIcon={<Edit />}
    />
    <MenuItem
      primaryText={"Verwijderen"}
      onClick={deleteView}
      rightIcon={<Delete />}
    />
  </IconMenu>
);

const ToggleFilter = () => {
  const [filter, setFilter] = useReduxState(indexFilter);
  const toggleFilter = useCallback(
    () => setFilter(!filter),
    [filter, setFilter]
  );
  return (
    <IconButton
      title={`${filter ? "Verberg" : "Toon"} filters`}
      onClick={toggleFilter}
    >
      {filter ? <Fullscreen /> : <FullscreenExit />}
    </IconButton>
  );
};

const ColumnSearch = ({ value: initialValue, onChange }) => {
  const [value, setValue] = useState(initialValue);
  const [open, setOpen] = useState(Boolean(initialValue));
  const handleOpen = useCallback(() => setOpen(true), [setOpen]);
  const handleClear = useCallback(() => {
    setValue("");
    onChange("", true);
    setOpen(false);
  }, [onChange, setOpen]);
  const handleChange = useCallback(
    ({ target: { value } }) => {
      setValue(value);
      return onChange(value, false);
    },
    [onChange]
  );
  return open ? (
    <div>
      <input type="text" value={value} onChange={handleChange} />
      <InlineIconButton onClick={handleClear}>
        <Clear />
      </InlineIconButton>
    </div>
  ) : (
    <InlineIconButton onClick={handleOpen}>
      <Search />
    </InlineIconButton>
  );
};

const header = (
  {
    properties,
    columns,
    isSearch = false,
    rows,
    order,
    onSort,
    filter,
    columnFilters,
    handleFilter,
    handleColumnFilter,
    indexSelection,
    setIndexSelection,
    toggleStatusDialog,
    updateStatusBusy,
    onExport,
    searchScope,
    saar = empty.object,
    selected = empty.array,
    updateSelected,
    handleUseBiebItems,
    hasCheckboxes = false,
  },
  views,
  saveView,
  editView,
  deleteView,
  viewId,
  setView,
  mayGlobal
) => {
  const hasViewsTools = Boolean(views);
  const hasViews = Boolean(views?.length);
  const currentView = views?.find(({ id }) => id === viewId);
  return (
    <TableHeader
      displaySelectAll={false}
      adjustForCheckbox={hasCheckboxes}
      key={viewId}
    >
      {rows.length > 0 && preheader(properties)}
      <TableRow>
        <TableHeaderColumn colSpan={properties.length || 1}>
          <div className="index-header">
            {hasViewsTools && (
              <div className="views">
                <RaisedButton
                  onClick={() => setView(emptyView)}
                  label="Standaard"
                />
                {hasViews && (
                  <DropDownMenu
                    value={viewId ?? 0}
                    onChange={(_event, _key, v) =>
                      setView(views.find(({ id }) => id === v))
                    }
                  >
                    <MenuItem value={0} primaryText="Weergave" disabled />
                    {flown(
                      views,
                      orderBy(["own"], ["desc"]),
                      map((view) => (
                        <MenuItem
                          key={view.id}
                          value={view.id}
                          primaryText={view.name}
                        />
                      ))
                    )}
                  </DropDownMenu>
                )}
                <Tools
                  disabled={
                    !currentView ||
                    (currentView.visibility === IndexViewVisibility.global &&
                      !mayGlobal)
                  }
                  deleteView={deleteView}
                  editView={editView}
                  saveView={saveView}
                />
                <IconButton onClick={() => editView(true)}>
                  <Add />
                </IconButton>
              </div>
            )}
            <div className="buttons">
              {indexSelection.length === 0 && (
                <>
                  <span className="index-search">
                    <KeywordInput
                      label={
                        isSearch
                          ? `Zoek in ${scopeLabel(searchScope, saar.name)}`
                          : "Zoek binnen het overzicht"
                      }
                      value={filter}
                      onChange={handleFilter}
                      scope={searchScope}
                      saar={saar}
                    />
                  </span>
                  <IconButton
                    title="Export naar Excel"
                    onClick={() => onExport(columns)}
                    disabled={selected.length > 0 || rows.length === 0}
                  >
                    <Download />
                  </IconButton>
                  {hasViewsTools && <ToggleFilter />}
                </>
              )}

              {selected.length > 0 && hasCheckboxes && (
                <>
                  <RaisedButton
                    label={`Selectie (${selected.length}) overnemen`}
                    onClick={handleUseBiebItems}
                    secondary
                    style={{ zIndex: 9999 }}
                  />
                  <RaisedButton
                    icon={<Delete />}
                    title="Selectie leegmaken"
                    onClick={() => updateSelected(empty.array)}
                    style={{ zIndex: 9999 }}
                  />
                </>
              )}
              {indexSelection.length > 0 && (
                <div
                  style={{
                    display: "inline-block",
                  }}
                >
                  <RaisedButton
                    label={`Selectie (${indexSelection.length}) wijzigen`}
                    onClick={toggleStatusDialog}
                    secondary
                    disabled={updateStatusBusy}
                    style={{ zIndex: 9999 }}
                  />
                  <RaisedButton
                    icon={<Delete />}
                    title="Selectie leegmaken"
                    onClick={() => setIndexSelection(empty.array)}
                    style={{ zIndex: 9999 }}
                  />
                </div>
              )}
            </div>
          </div>
        </TableHeaderColumn>
      </TableRow>
      <TableRow
        style={
          rows.length === 0 &&
          Object.keys(columnFilters ?? empty.object).length === 0
            ? displayNone
            : undefined
        }
      >
        {columns.map(({ name, property, alias }, i) => {
          return (
            <TableHeaderColumn
              key={property}
              style={cellStyle(properties, property)}
              className={cellClassNames(properties, property)}
            >
              {property !== "icoonlink" && (
                <>
                  <span className="sort" onClick={() => onSort(alias)}>
                    {name}
                    {(i === order.property || alias === order.property) &&
                      String.fromCharCode(
                        parseInt(order.descending ? "2193" : "2191", 16)
                      )}
                  </span>
                  {columnFilters && (
                    <ColumnSearch
                      value={columnFilters[alias] ?? ""}
                      onChange={(value, force) =>
                        handleColumnFilter(alias, value, force)
                      }
                    />
                  )}
                </>
              )}
            </TableHeaderColumn>
          );
        })}
      </TableRow>
    </TableHeader>
  );
};

export default header;
