import { composeAction } from "async-lifecycle";
import empty from "empty";

import { createBiebItem, doUseFromBieb, doUseMultiFromBieb } from "../api";
import { redirectAction } from "./common";
import { invalidateRecentlyCreatedAction } from "./dashboard";
import { getPageAction, invalidateFieldDefinitionsActions } from "./data";
import { invalidatePage, pageEditAction } from "./page";
import { invalidateRelations } from "./relations.invalidate";
import { persistentMultiLine, snackbarShowAction } from "./ui";
import { startJobsWatcher } from "./utils";

export const addToBiebDialogAction = (open) => ({
  type: "CMS_BIEB_ADD",
  payload: { open },
});

export const addToBiebAction = (newBiebItem) => (dispatch, getState) => {
  const {
    session: { miniSaar: { path = "iznet" } = empty.object } = empty.object,
  } = getState();

  return dispatch(
    composeAction({
      group: "CMS_BIEB_ADD",
      fire: createBiebItem,
      args: [newBiebItem],
      callbackSuccess: (payload, dispatch) => {
        dispatch(invalidateRecentlyCreatedAction());
        if (payload.itemId) {
          if (newBiebItem.moveItem) {
            dispatch(invalidatePage(payload.itemId));
            dispatch(getPageAction(payload.itemId));
          } else {
            const redirect = `/${path}/page/${payload.itemId}/${payload.slug}`;
            dispatch(redirectAction(redirect));
          }

          dispatch(pageEditAction(payload.itemId));
        }
      },
    })
  );
};

export const biebSelectSet = (selected) => ({
  type: "CMS_BIEB_SELECT_SET",
  payload: { selected },
});

const biebSelectClear = empty.functionThatReturns(
  Object.freeze({
    type: "CMS_BIEB_SELECT_CLEAR",
    payload: empty.object,
  })
);

export const doUseFromBiebDialogAction = (open, props = empty.object) => ({
  type: "CMS_BIEB_USE_SET",
  payload: { ...props, open },
});

export const doUpdateFromBiebDialogAction = (open, props = empty.object) => ({
  type: "CMS_BIEB_UPDATE_SET",
  payload: { ...props, open },
});

const biebBackgroundProcessMessage =
  "De selectie wordt overgenomen in de achtergrond. Rechtsboven in het scherm verandert het icoon van de Bieb gedurende het proces in een zandloper.";

export const doUseFromBiebAction = (model) => (dispatch, getState) => {
  const {
    session: { miniSaar: { path = "iznet" } = empty.object } = empty.object,
  } = getState();

  if (Array.isArray(model)) {
    dispatch(
      composeAction({
        group: "CMS_BIEB_USE",
        fire: doUseMultiFromBieb,
        args: [model],
        callbackSuccess: (payload, dispatch) => {
          dispatch(biebSelectClear());
          dispatch(invalidateRecentlyCreatedAction());
          for (const invalidateAction of invalidateFieldDefinitionsActions) {
            dispatch(invalidateAction);
          }

          for (const { itemId } of model) {
            dispatch(invalidateRelations(itemId));
          }

          if (payload.length === 0) {
            dispatch(
              snackbarShowAction(
                biebBackgroundProcessMessage,
                persistentMultiLine
              )
            );
            startJobsWatcher(dispatch, false, "BiebUseJob");
            dispatch(doUseFromBiebDialogAction(true, { created: empty.array }));
          } else {
            dispatch(doUseFromBiebDialogAction(true, { created: payload }));
          }
        },
      })
    );
  } else {
    dispatch(
      composeAction({
        group: "CMS_BIEB_USE",
        fire: doUseFromBieb,
        args: [model],
        callbackSuccess: (payload, dispatch) => {
          dispatch(biebSelectClear());
          dispatch(invalidateRecentlyCreatedAction());
          dispatch(invalidateRelations(model.itemId));
          if (payload.itemId) {
            dispatch(doUseFromBiebDialogAction(false));
            dispatch(
              redirectAction(`/${path}/page/${payload.itemId}/${payload.slug}`)
            );
            dispatch(pageEditAction(payload.itemId));
          } else {
            dispatch(
              snackbarShowAction(
                biebBackgroundProcessMessage,
                persistentMultiLine
              )
            );
            startJobsWatcher(dispatch, false, "BiebUseJob");
            dispatch(doUseFromBiebDialogAction(true, { created: empty.array }));
          }
        },
      })
    );
  }
};

export const closePreview = () => ({
  type: "CMS_BIEB_PREVIEW_CLEAR",
  payload: empty.object,
});

export const setPreview = (itemId) => {
  const itemIdNumber = parseInt(itemId, 10);
  return {
    type: "CMS_BIEB_PREVIEW_SET",
    payload: { itemId: Number.isNaN(itemIdNumber) ? undefined : itemIdNumber },
  };
};
