/* eslint  react/prop-types: off */
import empty from "empty";
import { keys, map } from "lodash/fp";
import { CardText, CardTitle } from "material-ui/Card";
import React from "react";

import { patchNameLinksRechts } from "../../../business/definitions";
import { flown } from "../../../lodash";
import Field, { LabelValue } from "../../content/field";
import ClusterCard from "../../material/clusterCard";
import { ItemLink } from "../../relation.itemlink";

const toObject = (a) => {
  if (!Array.isArray(a)) {
    return a;
  }

  const result = {};

  a.forEach(({ name, value }) => {
    result[name] = toObject(value);
  });

  return result;
};

const LandscapePopup = ({
  popup: { grouped = empty.object } = empty.object,
  rectangles = empty.array,
  pages = empty.object,
  bewerkingPerItem = empty.object,
  entiteitPerItem = empty.object,
  modulePerItem = empty.object,
}) =>
  grouped && Object.keys(grouped).length > 0 ? (
    <div className="landscape-popup">
      {flown(
        grouped,
        keys,
        map((dir) => {
          const [labelVan, labelNar] = dir
            .split(":")
            .map((id) => rectangles[id].text);
          return grouped[dir].map(({ siteLink = empty.object }) => {
            const { itemId, label } = siteLink;
            const {
              page: {
                inhoud: {
                  omschrijving: {
                    automatiseringsgraad,
                    statusVanKoppeling,
                  } = empty.object,
                } = empty.object,
              } = empty.object,
            } = pages[itemId] || empty.object;
            const bewerkingen = bewerkingPerItem[itemId] || empty.array;
            const entiteiten = entiteitPerItem[itemId] || empty.array;
            const modules = modulePerItem[itemId] || empty.array;
            const metaFields = grouped[dir]
              .flatMap(({ meta }) =>
                Array.isArray(meta) ? [toObject(meta)] : empty.array
              )
              .flatMap(({ page = empty.object }) =>
                Object.values(page).filter(
                  (f) => typeof f === "object" && f?.iproxType === "field"
                )
              );

            return (
              <ClusterCard key={itemId}>
                <CardTitle
                  className="card-title"
                  title={<ItemLink item={siteLink}>{label}</ItemLink>}
                />
                <CardText>
                  <LabelValue label="Route">
                    Van {labelVan} naar {labelNar}
                  </LabelValue>
                  {!!bewerkingen.length && (
                    <LabelValue label="Bewerkingsapplicatie">
                      {bewerkingen.map((bewerking, i) => (
                        <span key={bewerking.itemId}>
                          {i > 0 && ", "}
                          <ItemLink item={bewerking} />
                        </span>
                      ))}
                    </LabelValue>
                  )}
                  {!!entiteiten.length && (
                    <LabelValue label="Entiteit">
                      {entiteiten.map((entiteit, i) => (
                        <span key={entiteit.itemId}>
                          {i > 0 && ", "}
                          <ItemLink item={entiteit} />
                        </span>
                      ))}
                    </LabelValue>
                  )}
                  {!!modules.length && (
                    <LabelValue label="Module">
                      {modules.map((module, i) => (
                        <span key={module.itemId}>
                          {i > 0 && ", "}
                          <ItemLink item={module} />
                        </span>
                      ))}
                    </LabelValue>
                  )}

                  {statusVanKoppeling?.isEmpty === false && (
                    <Field field={statusVanKoppeling} />
                  )}
                  {automatiseringsgraad?.isEmpty === false && (
                    <Field field={automatiseringsgraad} />
                  )}
                  {metaFields.map((field) => {
                    const label = patchNameLinksRechts(
                      field.definition.name,
                      labelVan,
                      labelNar
                    );
                    return <Field key={field.id} field={field} name={label} />;
                  })}
                </CardText>
              </ClusterCard>
            );
          });
        })
      )}
    </div>
  ) : null;

export default LandscapePopup;
