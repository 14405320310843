/* eslint  react/prop-types: off */
import classNames from "classnames";
import empty from "empty";
import {
  at,
  concat,
  debounce,
  filter,
  find,
  get,
  head,
  isEqual,
  map,
  some,
  take,
} from "lodash/fp";
import { Card } from "material-ui/Card";
import FlatButton from "material-ui/FlatButton";
import {
  Table,
  TableBody,
  TableFooter,
  TableRow,
  TableRowColumn,
} from "material-ui/Table";
import PropTypes from "prop-types";
import React, {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { withRouter } from "react-router";
import {
  compose,
  defaultProps,
  mapProps,
  setDisplayName,
  setPropTypes,
  withHandlers,
  withState,
} from "recompose";

import { addStage } from "../../actions/common";
import { startJobsWatcher } from "../../actions/utils";
import { updateQueryString } from "../../api/fetch";
import { setResultSet, toSiteLink } from "../../business/store/resultset";
import { upperize } from "../../business/string";
import BiebPreviewDialog from "../../containers/bieb/biebPreviewDialog";
import { flown, leftJoin } from "../../lodash";
import { execOnChange } from "../../recompose.contrib";
import {
  columnsForIndex,
  indexFilter,
  indexItem,
  indexViews,
  recentIndexViews,
  setValidity,
} from "../../sagas";
import { userSelector } from "../../selectors";
import SafeInnerHtml from "../safeInnerHtml";
import { ScopeShape, isLibraryOnly } from "../search/constants";
import BulkUpdateDialog from "./bulkUpdateDialog";
import header, { emptyView } from "./header";
import IndexViewDialog from "./IndexViewDialog";
import { cellClassNames, cellStyle, preheader } from "./table";
import { displayValue, exportValue } from "./values";

const bodyCellStyles = Object.freeze({ icoonlink: { textAlign: "right" } });

const body = ({
  maxRows,
  rows,
  properties,
  highlightWord,
  hasCheckboxes = false,
  isSelected,
  columnFilters,
}) => (
  <TableBody
    displayRowCheckbox={hasCheckboxes}
    showRowHover
    deselectOnClickaway={false}
    preScanRows={false}
  >
    {(rows.length > 0 ||
      Object.keys(columnFilters ?? empty.object).length > 0) &&
      preheader(properties)}
    {rows.length === 0 ? (
      <TableRow selectable={false} className="hidden-checkbox">
        <TableRowColumn colSpan={properties.length || 1}>
          <SafeInnerHtml>Er zijn geen resultaten gevonden</SafeInnerHtml>
        </TableRowColumn>
      </TableRow>
    ) : (
      take(maxRows)(rows).map((obj, row) => {
        const discouraged = get("titel.discouraged")(obj);
        const overgeleverd = get("titel.overgeleverd")(obj);
        const overgenomen = get("titel.overgenomen")(obj);
        const overtenemen = get("titel.overtenemen")(obj);
        return (
          <TableRow
            key={row}
            rowNumber={row}
            selected={isSelected(row)}
            selectable={overtenemen}
            title={
              overgeleverd
                ? "Deze pagina is overgenomen vanuit deze Saar."
                : overgenomen
                ? "Deze pagina is al overgenomen."
                : undefined
            }
            className={classNames({
              "disabled-checkbox": !overtenemen,
              "discouraged-checkbox": discouraged,
            })}
          >
            {properties.map((property, i) => (
              <TableRowColumn
                key={i}
                className={cellClassNames(properties, property)}
                style={{
                  ...bodyCellStyles[property],
                  ...cellStyle(properties, property),
                }}
              >
                {obj.values?.[property]?.length > 1
                  ? obj.values[property].map((value, index) => (
                      <Fragment key={index}>
                        {displayValue(value, highlightWord)}
                        {index < obj.values[property].length - 1 && <br />}
                      </Fragment>
                    ))
                  : displayValue(obj[property], highlightWord)}
              </TableRowColumn>
            ))}
          </TableRow>
        );
      })
    )}
  </TableBody>
);

const footer = ({
  maxRows,
  rows: { length },
  properties: { length: colSpan },
  expandRows,
}) => (
  <TableFooter>
    <TableRow>
      <TableRowColumn colSpan={colSpan}>
        <span
          style={{
            textTransform: "uppercase",
            fontSize: "14px",
            marginRight: "1em",
            display: "inline-block",
            verticalAlign: "middle",
          }}
        >
          {maxRows} van {length} getoond
        </span>
        <FlatButton primary label="Toon meer" onClick={expandRows} />
      </TableRowColumn>
    </TableRow>
  </TableFooter>
);

export const defaultInitialOrder = Object.freeze({ descending: false });

const ItemList = ({ Page, ...props }) => {
  const {
    rows = empty.array,
    handleRowSelection = empty.func,
    isSearch,
    searchScope,
    selectItem,
    user: { mayEdit = false } = empty.object,
    itemId,
    queryString,
    slug,
    title,
    setFilter,
    setColumnFilter,
    setQueryString,
    setOrder,
  } = props;

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setResultSet(itemId, slug, title, rows.map(toSiteLink)));
  }, [dispatch, itemId, slug, title, rows]);

  useEffect(
    () => {
      dispatch({ type: indexViews.events.clear });
      if (itemId) {
        dispatch(indexItem.set(itemId));
        dispatch(
          indexViews.require({
            onSuccess: ({ body }) => {
              dispatch(
                recentIndexViews.require(itemId, {
                  onSuccess: ({ body: { idxVewIdt } }) => {
                    const view = body.find(({ id }) => id === idxVewIdt);
                    if (view) {
                      setView(view);
                    }
                  },
                })
              );
            },
          })
        );
        dispatch(
          columnsForIndex.require({
            id: itemId,
            pagetypes: props.paginatypefilter,
          })
        );
      } else {
        dispatch({ type: indexViews.events.clear });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch, itemId]
  );

  const usedViews = useSelector(indexViews.select).value;
  const views = itemId ? usedViews : undefined;
  const [viewId, setViewId] = useState(null);
  const [editingAsNew, setEditingAsNew] = useState(undefined);
  const [prevProperties, setPrevProperties] = useState(props.properties);
  const defaultColumns = props.properties.map((p) => ({
    name: upperize(p),
    alias: p,
  }));
  const [columns, setColumns] = useState(defaultColumns);
  if (
    (!itemId || !viewId) &&
    props.properties.length > 0 &&
    !isEqual(prevProperties, props.properties)
  ) {
    setPrevProperties(props.properties);
    setColumns(defaultColumns);
  }

  const editView = (asNew) => setEditingAsNew(asNew);
  const createView = ({ name, visibility, columns }) =>
    dispatch(
      indexViews.create(
        {
          name,
          visibility,
          columns,
          filter: props.filter,
          columnFilters: props.columnFilters,
          order: props.order,
          queryString,
        },
        {
          onSuccess: ({ body: { id } }) => {
            setViewId(id);
            dispatch({ type: indexViews.events.invalidate });
            dispatch({ type: indexViews.events.require });
          },
        }
      )
    );
  const updateView = (view) =>
    dispatch(
      indexViews.update({
        ...view,
        filter: props.filter,
        columnFilters: props.columnFilters,
        order: props.order,
        queryString,
      })
    );
  const deleteView = () =>
    dispatch(
      addStage({
        message: "Deze weergave wordt definitief verwijderd.",
        action: indexViews.delete(
          views.find(({ id }) => id === viewId),
          {
            onSuccess: () => {
              setView(emptyView);
            },
          }
        ),
      })
    );
  // Update view id
  useEffect(() => {
    if (viewId < 0 && views?.length && !views.includes(({ id }) => id < 0)) {
      const newView = views[views?.length - 1];
      setViewId(newView.id);
      setQueryString(
        updateQueryString(newView.queryString, { viewId: newView.id }, true)
      );
    }
  }, [setQueryString, views, viewId]);
  const setView = useCallback(
    (view) => {
      setViewId(view.id);
      setFilter(view.filter);
      for (const [property] of Object.entries(props.columnFilters)) {
        setColumnFilter(property, "");
      }
      for (const [property, value] of Object.entries(
        view.columnFilters ?? empty.object
      )) {
        setColumnFilter(property, value);
      }
      setQueryString(
        updateQueryString(view.queryString, { viewId: view.id }, true)
      );
      setOrder(view.order);
      setColumns(view.columns?.length > 0 ? view.columns : defaultColumns);

      dispatch(
        recentIndexViews.update({ id: itemId, idxVewIdt: view.id || undefined })
      );
    },
    [
      defaultColumns,
      dispatch,
      itemId,
      props.columnFilters,
      setColumnFilter,
      setFilter,
      setOrder,
      setQueryString,
    ]
  );
  // Select view from query string
  useEffect(() => {
    const queryStringViewId = Number(
      new URLSearchParams(queryString).get("viewId")
    );
    if (viewId === queryStringViewId) {
      return;
    }

    const queryStringView = views?.find(({ id }) => id === queryStringViewId);
    if (queryStringView) {
      setView(queryStringView);
    }
  }, [queryString, setView, views, viewId]);
  const saveView = () => {
    const view = views?.find(({ id }) => id === viewId);
    if (view) {
      updateView(view);
    }
  };

  const user = useSelector(userSelector);
  const hasBiebCheckboxes = mayEdit && isLibraryOnly(searchScope);
  const hasIndexCheckboxes =
    !!itemId && !!user.tasks?.some(({ prfIdt }) => prfIdt === 6);
  const [indexSelection, setIndexSelection] = useState([]);
  const [updateStatusBusy, setUpdateStatusBusy] = useState(false);
  const hasCheckboxes = hasBiebCheckboxes || hasIndexCheckboxes;
  const fullscreen = !useSelector(indexFilter.select);
  const onRowSelection = hasBiebCheckboxes
    ? handleRowSelection
    : (selection) => setIndexSelection(selection);
  const indexSelectionItemIds = useMemo(
    () => indexSelection.map((i) => intoItemId(rows[i - 1])),
    [indexSelection, rows]
  );
  const updateStatus = useCallback(
    (validity, itemIds) => {
      setUpdateStatusBusy(true);
      dispatch(
        setValidity.require(
          { itemIds, validity },
          {
            onSuccess: () => {
              startJobsWatcher(dispatch, false, "StatusUpdateJob");
              setStatusDialog(false);
              setIndexSelection([]);
              setUpdateStatusBusy(false);
            },
            onFail: () => {
              setUpdateStatusBusy(false);
            },
          }
        )
      );
    },
    [dispatch]
  );
  const [statusDialog, setStatusDialog] = useState(false);
  const toggleStatusDialog = useCallback(
    () => setStatusDialog((value) => !value),
    []
  );
  const propsWithHasCheckboxes = {
    ...props,
    isSelected: hasBiebCheckboxes
      ? props.isSelected
      : (index) => indexSelection.includes(index + 1),
    hasCheckboxes,
    indexSelection,
    setIndexSelection,
    toggleStatusDialog,
    updateStatusBusy,
    columns: fullscreen ? columns : columns.slice(0, 5),
    properties: columns
      .map((c) => c.alias)
      .slice(0, fullscreen ? columns.length : 5),
  };
  const mayGlobal = useSelector(userSelector)?.iznet === "Write";

  return (
    <Card style={{ padding: "16px 0" }}>
      {isLibraryOnly(searchScope) && (
        <BiebPreviewDialog selectItem={selectItem} Page={Page} />
      )}
      {hasIndexCheckboxes && statusDialog && (
        <BulkUpdateDialog
          open
          onCancel={toggleStatusDialog}
          onConfirm={updateStatus}
          itemId={itemId}
          itemIds={indexSelectionItemIds}
        />
      )}
      {editingAsNew !== undefined && (
        <IndexViewDialog
          view={
            views?.find(({ id }) => !editingAsNew && id === viewId) || {
              ...emptyView,
              columns,
            }
          }
          onCancel={() => setEditingAsNew(undefined)}
          onConfirm={(view) => {
            if (editingAsNew) {
              createView(view);
            } else {
              updateView(view);
            }
            setColumns(view.columns);
            setEditingAsNew(undefined);
          }}
        />
      )}

      <Table
        selectable={hasCheckboxes}
        multiSelectable={hasCheckboxes}
        height="80ex"
        onRowSelection={onRowSelection}
        className={classNames("list", { search: isSearch })}
      >
        {header(
          propsWithHasCheckboxes,
          views,
          saveView,
          editView,
          deleteView,
          viewId,
          setView,
          mayGlobal
        )}
        {body(propsWithHasCheckboxes)}
        {props.maxRows < rows.length && footer(propsWithHasCheckboxes)}
      </Table>
    </Card>
  );
};

const intoItem = ({ titel: { itemId, label }, pagetype }) => ({
  itemId: parseInt(itemId, 10),
  label,
  pagetype,
});

const intoItemId = ({ titel: { itemId } }) => parseInt(itemId, 10);

export default compose(
  setDisplayName("ItemList"),
  defaultProps(
    Object.freeze({
      isSearch: false,
      initialRows: 25,
      properties: empty.array,
    })
  ),
  setPropTypes({
    isSearch: PropTypes.bool.isRequired,
    searchScope: ScopeShape,
    saar: PropTypes.object,
    exportData: PropTypes.func.isRequired,
    list: PropTypes.arrayOf(
      PropTypes.shape({
        lookup: PropTypes.object,
        fields: PropTypes.shape({
          itemId: PropTypes.string.isRequired,
          label: PropTypes.string.isRequired,
          slug: PropTypes.string.isRequired,
          pagetype: PropTypes.string.isRequired,
          pubSttDtm: PropTypes.string.isRequired,
          sam: PropTypes.string,
        }).isRequired,
      })
    ),
    properties: PropTypes.arrayOf(PropTypes.string).isRequired,
    filter: PropTypes.string,
    setFilter: PropTypes.func.isRequired,
    order: PropTypes.shape({
      property: PropTypes.oneOfType([
        PropTypes.number.isRequired,
        PropTypes.string.isRequired,
      ]),
      descending: PropTypes.bool.isRequired,
    }).isRequired,
    setOrder: PropTypes.func.isRequired,
    selections: PropTypes.object.isRequired,
    setSelections: PropTypes.func.isRequired,
    title: PropTypes.string.isRequired,
    highlightWord: PropTypes.string,
    onSelect: PropTypes.func.isRequired,
    onDeselect: PropTypes.func.isRequired,
    rows: PropTypes.arrayOf(PropTypes.object.isRequired).isRequired,
    debounceMs: PropTypes.number,
    user: PropTypes.object,
    onUseBiebItems: PropTypes.func,
  }),
  withState("maxRows", "updateMaxRows", ({ initialRows }) => initialRows),
  withHandlers({
    onSort:
      ({ order, setOrder }) =>
      (property) =>
        setOrder({
          property,
          descending: property === order.property ? !order.descending : false,
        }),
    resetMaxRows:
      ({ initialRows, updateMaxRows }) =>
      () =>
        updateMaxRows(initialRows),
    expandRows:
      ({ maxRows, updateMaxRows }) =>
      () =>
        updateMaxRows(2 * maxRows),
  }),
  execOnChange("resetMaxRows", "rows"),
  withRouter,
  mapProps((props) => {
    const {
      isSearch,
      searchScope,
      saar,
      exportData,
      facets,
      filter,
      columnFilters,
      highlightWord,
      location,
      router,
      onDeselect,
      onSelect,
      onSort,
      order,
      paginatypefilter = empty.array,
      properties = empty.array,
      rows = empty.array,
      maxRows,
      expandRows,
      setFilter,
      setColumnFilter,
      setOrder,
      title,
      debounceMs,
      user,
      onUseBiebItems,
      Page,
      selected = empty.array,
      updateSelected,
      uid,
      slug,
      setSelections,
    } = props;
    const setQueryString = (queryString) => {
      if (queryString?.length > 0) {
        router.replace({
          pathname: location.pathname,
          search: queryString,
        });
      } else {
        setSelections(new Map());
      }
    };
    return {
      onSort,
      order,
      paginatypefilter,
      filter,
      columnFilters,
      properties,
      isSearch,
      searchScope,
      saar,
      setFilter,
      setColumnFilter,
      facets,
      rows,
      maxRows: rows.length <= maxRows + 5 ? rows.length : maxRows,
      expandRows,
      onExport: (columns) => {
        exportData(
          title,
          columns.map(({ name }) => name),
          rows.map((obj) =>
            columns.map(({ alias: prop }) =>
              (obj.values[prop] ?? empty.array).map(exportValue).join(", ")
            )
          )
        );
      },
      onSelect,
      onDeselect,
      highlightWord,
      debounceMs,
      user,
      onUseBiebItems,
      Page,
      selected,
      updateSelected,
      itemId: typeof uid === "number" ? uid : null,
      slug,
      title,
      queryString: location.search,
      setQueryString,
      setOrder,
    };
  }),
  withHandlers(({ debounceMs = 200, setFilter, setColumnFilter }) => {
    const debouncedSetFilter = debounce(debounceMs)((filter) =>
      setFilter(filter.toLowerCase())
    );
    const debouncedSetColumnFilter = debounce(debounceMs)((filter) => {
      const loc = filter.indexOf(":");
      setColumnFilter(
        filter.substring(0, loc),
        filter.substring(loc + 1).toLowerCase()
      );
    });
    return {
      handleFilter: () => (newValue, force) => {
        debouncedSetFilter(newValue);
        if (force) {
          debouncedSetFilter.flush();
        }
      },

      handleColumnFilter: () => (property, value, force) => {
        debouncedSetColumnFilter(`${property}:${value}`);
        if (force) {
          debouncedSetColumnFilter.flush();
        }
      },

      isSelected:
        ({ rows, selected }) =>
        (index) =>
          index < rows.length &&
          some({ itemId: intoItem(rows[index]).itemId })(selected),

      handleRowSelection:
        ({ rows, selected, updateSelected }) =>
        (selectedRows) => {
          const left = flown(
            selected,
            leftJoin(({ itemId }) => itemId, rows, intoItemId),
            filter(([, right]) => right === undefined),
            map(head)
          );
          const positions = flown(
            selectedRows,
            map((i) => i - 1)
          );
          flown(
            rows,
            at(positions),
            map(intoItem),
            concat(left),
            updateSelected
          );
        },

      selectItem:
        ({ rows, selected, updateSelected }) =>
        ({ itemId }) => {
          const item = flown(
            rows,
            find((row) => intoItemId(row) === itemId),
            intoItem
          );
          updateSelected([...selected, item]);
        },

      handleUseBiebItems:
        ({ onUseBiebItems, selected }) =>
        () =>
          onUseBiebItems(selected),
    };
  }),
  setPropTypes({
    order: PropTypes.shape({
      property: PropTypes.number,
      descending: PropTypes.bool.isRequired,
    }).isRequired,
    onExport: PropTypes.func.isRequired,
    onSort: PropTypes.func.isRequired,
    onSelect: PropTypes.func.isRequired,
    onDeselect: PropTypes.func.isRequired,
    onUseBiebItems: PropTypes.func,
    properties: PropTypes.arrayOf(PropTypes.string).isRequired,
    rows: PropTypes.arrayOf(PropTypes.object.isRequired).isRequired,
    setFilter: PropTypes.func.isRequired,
    setColumnFilter: PropTypes.func.isRequired,
    handleFilter: PropTypes.func.isRequired,
    handleColumnFilter: PropTypes.func.isRequired,
    highlightWord: PropTypes.string,
    isSelected: PropTypes.func.isRequired,
    handleRowSelection: PropTypes.func.isRequired,
    handleUseBiebItems: PropTypes.func.isRequired,
  })
)(ItemList);
