import { identity } from "lodash/fp";

import { enhanceApplication } from "../../containers/pagetypes/application";
import Dashboard from "../../containers/pagetypes/dashboard";
import { enhanceEntiteit } from "../../containers/pagetypes/entiteit";
import Index, { enhanceIndex } from "../../containers/pagetypes/index";
import { enhanceKoppeling } from "../../containers/pagetypes/koppeling";
import { enhanceModule } from "../../containers/pagetypes/module";
import { enhanceProcess } from "../../containers/pagetypes/process";
import enhanceRaamwerk from "../../containers/pagetypes/raamwerk";
import { enhanceSupplier } from "../../containers/pagetypes/supplier";
import ApplicationLandscape from "./applicationLandscape";
import DefaultPage from "./defaultPage";
import Legoblok from "./legoblok";
import Treeview from "./treeview";
import Verwerkingen from "./verwerkingen";

const containerFunctions = {
  activiteit: enhanceProcess,
  applicatie: enhanceApplication,
  bedrijfsproces: enhanceProcess,
  entiteit: enhanceEntiteit,
  index: enhanceIndex,
  koppeling: enhanceKoppeling,
  leverancier: enhanceSupplier,
  module: enhanceModule,
  procesgroep: enhanceProcess,
  procesonderdeel: enhanceProcess,
  raamwerk: enhanceRaamwerk,
  praktijksituatie: enhanceRaamwerk,
};

const pagetypes = {
  applicatielandschap: ApplicationLandscape,
  dashboard: Dashboard,
  index: Index,
  legoblok: Legoblok,
  overzicht: Verwerkingen,
  treeview: Treeview,
};

export const getFallbackComponent = (pagetype) =>
  (pagetype && pagetypes[pagetype.toLowerCase()]) || DefaultPage;

export const enhanceComponent = (pagetype) =>
  containerFunctions[pagetype.toLowerCase()] || identity;
