/* eslint  react/prop-types: off */
import classNames from "classnames";
import empty from "empty";
import { Card } from "material-ui/Card";
import React from "react";

import { titleCase } from "../../../utils";
import SaarSubheader from "../../material/Subheader";

const LandscapeLegend = ({
  automatiseringsgraadList: { items = empty.array } = empty.object,
  fromItem = false,
  pagetype,
  fromItemType,
}) => {
  const isApplication = pagetype === "applicatie";
  const isModule = pagetype === "module";
  const itemPagetype = isModule ? "applicatie" : pagetype;
  const showBewerkingsapplicatie =
    isApplication && fromItemType === "applicatie";
  const crossDomain = fromItemType !== pagetype;
  const y = (value) =>
    fromItem ? (showBewerkingsapplicatie ? value + 46 : value) : value - 46;
  const y2 = (value) => y(value) - (isApplication ? 0 : isModule ? 46 : 92);
  const maxHeight = y2(297 + (isApplication ? items.length * 40 : 0));
  return (
    <Card className="legend-wrapper" style={{ marginBottom: "16px" }}>
      <SaarSubheader>Legenda</SaarSubheader>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox={`0 0 225 ${maxHeight}`}
        style={{ maxWidth: "225px", maxHeight: `${maxHeight}px` }}
      >
        <g className="legend">
          {fromItem && (
            <>
              <rect x="0" y="16" width="50" height="30" fill="#ffc107" />
              <text x="60" y="36">
                {crossDomain ? `Bron voor ${fromItemType}` : `Deze ${pagetype}`}
              </text>
            </>
          )}
          {showBewerkingsapplicatie && (
            <>
              <circle r="6" cx="25" cy="74" fill="#ffc107" />
              <text x="60" y="72">
                Deze {pagetype} als
              </text>
              <text x="60" y="92">
                bewerkingsapplicatie
              </text>
            </>
          )}
          <rect x="0" y={y(62)} width="50" height="30" fill="#8bc34a" />
          <text x="60" y={y(82)}>
            {fromItem && !crossDomain && !isModule
              ? isApplication
                ? `Andere interne ${itemPagetype}`
                : `Andere ${itemPagetype}`
              : isApplication || isModule
              ? `Interne ${itemPagetype}`
              : titleCase(itemPagetype)}
          </text>
          {(isApplication || isModule) && (
            <>
              <rect x="0" y={y(108)} width="50" height="30" fill="#03a9f4" />
              <text x="60" y={y(128)}>
                {fromItem && !crossDomain && !isModule
                  ? "Andere externe "
                  : "Externe "}
                {itemPagetype}
              </text>
            </>
          )}
          {isApplication && (
            <>
              <rect x="0" y={y(156)} width="50" height="30" fill="#9c27b0" />
              <text x="60" y={y(166)}>
                Toekomstige applicatie
              </text>
              <text x="60" y={y(186)}>
                of koppeling
              </text>
            </>
          )}
          <rect x="0" y={y2(204)} width="50" height="30" fill="#9e9e9e" />
          {isApplication && (
            <>
              <text x="60" y={y2(214)}>
                Uitgefaseerde applicatie
              </text>
              <text x="60" y={y2(234)}>
                of koppeling
              </text>
            </>
          )}
          {!isApplication && (
            <>
              <text x="60" y={y2(224)}>
                Gearchiveerde {itemPagetype}
              </text>
            </>
          )}
          <path
            className="real none"
            d={`M 0 ${y2(269)} L 50 ${y2(269)}`}
            fill="transparent"
            stroke="#000"
            strokeWidth="2"
            markerEnd="url(#arrow)"
          />
          <text x="60" y={y2(273)}>
            {isApplication ? "Onbekend" : "Relatie"}
          </text>
          {isApplication &&
            items.map(({ id, alias, value }, idx) => {
              const yLine = 309 + idx * 40;
              const yText = 313 + idx * 40;
              return (
                <g key={id}>
                  <path
                    className={classNames("real", alias)}
                    d={`M 0 ${y2(yLine)} L 50 ${y2(yLine)}`}
                    fill="transparent"
                    stroke="#8bc34a"
                    strokeWidth="2"
                    markerEnd="url(#arrow)"
                  />
                  <text x="60" y={y2(yText)}>
                    {value}
                  </text>
                </g>
              );
            })}
        </g>
      </svg>
    </Card>
  );
};

export default LandscapeLegend;
