import { composeAction } from "async-lifecycle";
import empty from "empty";
import FileSaver from "file-saver";
import {
  defaultTo,
  filter,
  flatten,
  flow,
  get,
  identity,
  isEqual,
  map,
  pick,
  uniq,
} from "lodash/fp";
import moment from "moment";
import { hashHistory } from "react-router";

import {
  addItemNote,
  deleteItemNote,
  exportWord,
  getClusterDefinition,
  getFieldDefinitions,
  getItemNotes,
  getKoppelingFields,
  getMediaWidget,
  getMiniSaars,
  getNavigation,
  getOneDriveFileInfo,
  getPage,
  getPagetypeList,
  getPresentation,
  getRaamwerk,
  getRaamwerkPagetypes,
  getRaamwerkTargets,
  getRecent,
  getRecentOverzicht,
  getRelationTypes,
  getRelations,
  getSelectionLists,
  getSiblings,
  getTree,
  getVersionDiff,
  getVersions,
  searchFull,
  searchLinks,
  setRecent,
} from "../api";
import { getSettings } from "../api/fetch";
import { pageStatus } from "../business";
import { Side } from "../business/relations";
import { flown } from "../lodash";
import {
  pagetypeGroupFromAlias,
  pagetypeGroups,
  pagetypeHasProcessTree,
} from "../pagetypes";
import { bulkcopy } from "../sagas";
import { versionKey } from "../selectors/versions";
import { structuredMap, withLookup } from "../utils";
import { addStage, redirectAction } from "./common";
import {
  requireEntityLandscapeBetweenAction,
  requireExpandedLandscapeAction,
  requireLandscapeAction,
  requireLandscapeBetweenAction,
  requireLandscapeForKoppelingAction,
} from "./landscape";
import { leaveAction } from "./leave";
import { persistentMultiLine, singleLine, snackbarShowAction } from "./ui";

const { proces, applicatielandschap, treeview, raamwerk } = pagetypeGroups;

export const requireNavigationAction = () =>
  composeAction({
    group: "NAVIGATION",
    fire: () => getNavigation(),
    cachekey: "navigation",
  });

export const requireMiniSaarsAction = () =>
  composeAction({
    group: "MINISAARS",
    fire: () =>
      getMiniSaars().then(
        structuredMap({ miniSaars: withLookup("variantId") })
      ),
    cachekey: "miniSaars",
  });

export const requireRelationTypesAction = () =>
  composeAction({
    group: "RELATIONTYPES",
    fire: () =>
      getRelationTypes().then(structuredMap({ types: withLookup("alias") })),
    cachekey: "relationTypes",
  });

export const requireClusterDefinition = (itemId, clusterId, name) => {
  const uid = `${itemId}:${clusterId ? `${name}_${clusterId}` : name}`;
  return composeAction({
    group: "CLUSTERDEFINITION",
    fire: (_, itemId, clusterId, name) =>
      getClusterDefinition(itemId, clusterId, name),
    args: [uid, itemId, clusterId, name],
    key: "id",
    extra: { id: uid },
  });
};

const pagetypeFromPayload = (
  { page: { pagetype } = empty.object } = empty.object
) => pagetype;

const pagetypeGroupFromPayload = flow(
  pagetypeFromPayload,
  pagetypeGroupFromAlias
);

export const requireTreeAction = (
  id,
  ids = empty.array,
  areRootItems = false,
  paginatypen = empty.array
) =>
  composeAction({
    group: "TREE",
    fire: (id) => getTree(id, ids, areRootItems, paginatypen),
    args: [id],
    key: "id",
    cachekey: "tree",
  });

const requireRaamwerkPagetypesAction = (id, raamwerkAlias) => {
  const cacheKey = raamwerkAlias;
  return composeAction({
    group: "RAAMWERKPAGETYPES",
    fire: (_, id, raamwerkAlias) => getRaamwerkPagetypes(id, raamwerkAlias),
    args: [cacheKey, id, raamwerkAlias],
    key: "id",
    extra: { id: cacheKey },
    cachekey: "raamwerkPagetypes",
  });
};

const requireRaamwerkAction = (id) =>
  composeAction({
    group: "RAAMWERK",
    fire: getRaamwerk,
    args: [id],
    key: "id",
    cachekey: "raamwerk",
    callbackSuccess: (payload, dispatch, [id] = empty.array) => {
      const raamwerkAlias = get("list.alias")(payload);
      if (id && raamwerkAlias) {
        dispatch(requireRaamwerkPagetypesAction(id, raamwerkAlias));
      }
    },
  });

export const requireRaamwerkTargetsAction = (
  id,
  pagetypeId,
  selectionId,
  organizationIds
) =>
  composeAction({
    group: "LINKRAAMWERK_ITEMS",
    fire: getRaamwerkTargets,
    args: [id, pagetypeId, selectionId, organizationIds],
    callbackBefore: (dispatch) => {
      dispatch({ type: "LINKRAAMWERK_ITEMS_CLEAR", payload: {} });
    },
  });

const relationsOnlyAction = (id) => ({
  group: "RELATIONS",
  fire: (id) => getRelations(id),
  args: [id],
  key: "id",
  cachekey: "relations",
});

export const requireRelationsOnlyAction = flow(
  relationsOnlyAction,
  composeAction
);

const requireRelationsAction = (
  id,
  _pagetypeGroup,
  requiresProcessTree,
  requiresLandscape,
  pagetype
) =>
  composeAction(relationsOnlyAction(id), {
    callbackSuccess: (payload, dispatch, [id] = empty.array) => {
      if (id && payload.relations && payload.relations.length > 0) {
        if (requiresLandscape) {
          switch (true) {
            case pagetype === "applicatie":
              dispatch(requireLandscapeAction(id, payload.relations));
              break;
            case pagetype === "entiteit":
              dispatch(
                requireLandscapeBetweenAction(
                  id,
                  payload.relations,
                  "applicatie_applicatie"
                )
              );
              dispatch(
                requireEntityLandscapeBetweenAction(id, payload.relations)
              );
              break;
            case pagetype === "koppeling":
              dispatch(
                requireLandscapeForKoppelingAction(id, payload.relations)
              );
              break;
            case pagetype === "module":
              dispatch(
                requireLandscapeBetweenAction(
                  id,
                  payload.relations,
                  "applicatie_module"
                )
              );
              break;
            default:
              break;
          }
        }

        if (requiresProcessTree) {
          const itemIds = flow(
            map(({ side, left, right }) =>
              side === Side.left
                ? right
                : side === Side.right
                ? left
                : [left, right]
            ),
            flatten,
            filter(
              ({ type: pagetype }) =>
                pagetypeGroupFromAlias(pagetype) === proces
            ),
            map(({ itemId }) => itemId),
            uniq
          )(payload.relations);
          if (itemIds.length > 0) {
            dispatch(requireTreeAction(id, itemIds));
          }
        }
      }
    },
  });

const pageRelationsCallbacks = {
  callbackSuccess: (payload, dispatch, [id] = empty.array) => {
    if (id) {
      const pagetype = pagetypeFromPayload(payload);
      dispatch(
        requireRelationsAction(
          id,
          pagetypeGroupFromAlias(pagetype),
          pagetypeHasProcessTree(pagetype),
          true,
          pagetype
        )
      );
    }
  },
};

const pageRelationsOnlyCallbacks = {
  callbackSuccess: (payload, dispatch, [id] = empty.array) => {
    if (id) {
      const pagetype = pagetypeFromPayload(payload);
      dispatch(
        requireRelationsAction(
          id,
          pagetypeGroupFromAlias(pagetype),
          false,
          false,
          pagetype
        )
      );
    }
  },
};

export const requireSelectionListsAction = () =>
  composeAction({
    group: "SELECTIONLISTS",
    fire: () => getSelectionLists(),
    cachekey: "selectionLists",
  });

export const invalidateFieldDefinitionsActions = Object.freeze([
  { type: "DEFINITION_FIELD_INVALIDATE" },
  { type: "FIELDDEFINITIONS_BY_PAGETYPE_INVALIDATEALL" },
]);

export const requireFieldDefinitionsAction = () =>
  composeAction({
    group: "DEFINITION_FIELD",
    fire: getFieldDefinitions,
    cachekey: "fieldDefinitions",
    callbackSuccess: (_, dispatch) => dispatch(requireSelectionListsAction()),
  });

export const requirePagetypeListAction = () =>
  composeAction({
    group: "PAGETYPELIST",
    fire: () => getPagetypeList(),
    cachekey: "pagetypeList",
  });

export const requireSelectionAction = (id, query, full = false) =>
  composeAction({
    group: "SELECTION",
    fire: (id, query) => (full ? searchFull : searchLinks)(id, query),
    args: [id, query],
    key: "id",
    cachekey: "selection",
    callbackAfter: (_, dispatch) => {
      if (!full) {
        dispatch({ type: "SELECTION_INVALIDATE", payload: { id } });
        dispatch(requireSelectionAction(id, query, true));
      }
    },
    callbackSuccess: (_, dispatch) => {
      dispatch(requireSelectionListsAction());
      dispatch(requirePagetypeListAction());
    },
  });

const pageSelectionCallbacks = {
  callbackSuccess: (
    {
      page: {
        selectie: { selectieInstellingen } = empty.object,
      } = empty.object,
    } = empty.object,
    dispatch,
    [id] = empty.array
  ) => {
    if (selectieInstellingen) {
      const {
        eigenschappen: {
          paginatypefilter: {
            value: paginatypefilter = empty.array,
          } = empty.object,
          statusfilter: { value: statusfilter = empty.array } = empty.object,
        } = empty.object,
      } = selectieInstellingen;
      const query = {
        paginatypen: (paginatypefilter || empty.array).map(
          ({ alias }) => alias
        ),
        startpunten: (
          (selectieInstellingen.startpunt &&
            selectieInstellingen.startpunt.clusters.map(({ link }) => link)) ||
          (selectieInstellingen.link || empty.object).fields ||
          empty.array
        ).map((link) => link.value.linkId),
        statusIds: (statusfilter || empty.array).map(
          ({ selectionId }) => selectionId
        ),
      };
      dispatch(requireSelectionAction(id, query));
    }
  },
};

export const requireRecentAction = empty.functionThatReturns(
  composeAction({
    group: "RECENT_ITEMS",
    fire: getRecent,
    cachekey: "recent",
  })
);

empty.functionThatReturns(
  Object.freeze({
    type: "RECENT_ITEMS_INVALIDATE",
  })
);

export const setVisitedAction =
  ({
    id,
    title,
    path,
    page: { pagetype } = empty.object,
    page = empty.object,
    siteId,
  }) =>
  (dispatch) => {
    if (!Number.isInteger(id) && typeof path !== "string") {
      return;
    }

    const status = pageStatus(page, pagetype);

    dispatch({
      type: "RECENT_ITEMS_UPDATE",
      payload: {
        itemId: id,
        label: title,
        path,
        structureId: siteId,
        type: pagetype || (title === "Saar Bieb" ? "bieb" : undefined),
        status: status && { alias: status },
      },
    });
    setRecent({ itemId: id, label: title, path });
  };

export const requireOverzichtAction = empty.functionThatReturns(
  composeAction({
    group: "OVERZICHT",
    fire: getRecentOverzicht,
    cachekey: "overzicht",
  })
);

export const invalidateOverzichtAction = empty.functionThatReturns(
  Object.freeze({
    type: "OVERZICHT_INVALIDATE",
  })
);

export const requireItemNotesAction = (id) => (dispatch) => {
  dispatch(
    composeAction({
      group: "ITEM_NOTES",
      fire: (id) => getItemNotes(id),
      args: [id],
      key: "id",
    })
  );
};

const updateItemNoteCallbacks = {
  callbackSuccess: (payload, dispatch, [id] = empty.array) => {
    if (id) {
      dispatch(requireItemNotesAction(id));
    }
  },
};

export const updateItemNoteAction = (id, message) =>
  composeAction(
    {
      group: "UPDATE_ITEM_NOTE",
      fire: (id, message) => addItemNote(id, message),
      args: [id, message],
    },
    updateItemNoteCallbacks
  );

const realDeleteItemNoteAction = (id, noteId) =>
  composeAction({
    group: "DELETE_ITEM_NOTE",
    fire: (id, noteId) => deleteItemNote(noteId),
    args: [id, noteId],
    callbackAfter: (payload, dispatch, [id] = empty.array) =>
      dispatch({
        type: "DELETE_ITEM_NOTE",
        payload: { id, noteId },
      }),
  });

export const deleteItemNoteAction = (id, noteId) =>
  addStage({
    message: "De notitie wordt definitief verwijderd.",
    action: realDeleteItemNoteAction(id, noteId),
  });

const pageRecentlyCallbacks = {
  callbackBefore: (dispatch, [id] = empty.array) => {
    if (id) {
      dispatch(requireRecentAction());
      dispatch(requireItemNotesAction(id));
    }
  },
  callbackSuccess: (payload, dispatch) => {
    dispatch(setVisitedAction(payload));
  },
};

const requireSiblingsAction = (id) =>
  composeAction({
    group: "SIBLINGS",
    fire: (id) => getSiblings(id),
    args: [id],
    key: "id",
    cachekey: "siblings",
  });

const processCallbacks = {
  callbackSuccess: (payload, dispatch, [id] = empty.array) => {
    if (id && pagetypeGroupFromPayload(payload) === proces) {
      dispatch(requireTreeAction(id, [id]));
      dispatch(requireSiblingsAction(id));
    }

    if (id && pagetypeGroupFromPayload(payload) === treeview) {
      const startpunt = get("page.instellingen.startpunt.value.linkId")(
        payload
      );
      const paginatypen = flown(
        payload,
        get("page.instellingen.paginatypefilter.value"),
        map("alias"),
        defaultTo(empty.array)
      );
      dispatch(
        requireTreeAction(
          id,
          startpunt ? [startpunt] : empty.array,
          true,
          paginatypen
        )
      );
    }
  },
};

const landscapeCallbacks = {
  callbackSuccess: (payload, dispatch, [id] = empty.array) => {
    if (id && pagetypeGroupFromPayload(payload) === applicatielandschap) {
      const {
        page: {
          inhoud: { startpunt = empty.object, relatietypealias } = empty.object,
        } = empty.object,
      } = payload;

      const { value: { linkId } = empty.object } =
        startpunt && startpunt.value ? startpunt : empty.object;

      dispatch(
        requireExpandedLandscapeAction(
          id,
          linkId ? [linkId] : undefined,
          relatietypealias?.value
        )
      );
    }
  },
};

const raamwerkCallbacks = {
  callbackSuccess: (payload, dispatch, [id] = empty.array) => {
    if (id && pagetypeGroupFromPayload(payload) === raamwerk) {
      dispatch(requireRaamwerkAction(id));
    }
  },
};

const definitionCallbacks = {
  callbackSuccess: (_, dispatch) => dispatch(requireFieldDefinitionsAction()),
};

const requirePresentationAction = (pagetype) =>
  composeAction({
    group: "PRESENTATION",
    fire: getPresentation,
    args: [pagetype],
    key: "id",
    cachekey: "presentation",
  });

const pagePresentationCallbacks = {
  callbackSuccess: ({ page: { pagetype } = empty.object }, dispatch) => {
    dispatch(requirePresentationAction(pagetype));
  },
};

const requireVersionsAction = (itemId) =>
  composeAction({
    group: "VERSIONS",
    fire: getVersions,
    args: [itemId],
    key: "id",
    cachekey: "versions",
  });

const pageVersionsCallbacks = {
  callbackSuccess: (_, dispatch, [id]) => {
    dispatch(requireVersionsAction(id));
  },
};

export const noopAction = composeAction({
  group: "NOOP",
  fire: empty.functionThatReturns(empty.object),
  args: empty.array,
});

/**
 * GetPage Actioncreator
 * @param  {int} id IPROX ItmIdt
 * @param  {boolean} refresh Whether to force refresh
 * @param  {boolean} force Whether to bypass cache
 */
const pageAction = (id, refresh = false, force = refresh) => ({
  group: "PAGE",
  fire: (id, refresh) => getPage(id, refresh),
  args: [id, refresh],
  key: "id",
  cachekey: force ? undefined : "pages",
});

export const getVersionAction = (itemId, version) =>
  composeAction({
    group: "VERSIONDIFF",
    fire: (_, itemId, version) => getVersionDiff(itemId, version),
    args: [versionKey(itemId, version), itemId, version],
    key: "id",
    cachekey: "versionDiffs",
  });

const parseId = (id) => {
  switch (typeof id) {
    case "number":
      return id;
    case "string": {
      const parsed = Number(id);
      if (Number.isNaN(parsed)) {
        return 0;
      }

      return parsed;
    }

    default:
      return 0;
  }
};

/**
 * GetPage Actioncreator
 * @param  {int} rawId IPROX ItmIdt
 * @param refresh Whether to force refresh
 * @param force Whether to bypass cache
 */
export const getSinglePageAction = (rawId, refresh = false, force = false) => {
  const id = parseId(rawId);

  if (id <= 0) {
    return noopAction;
  }

  return composeAction(pageAction(id, refresh, force));
};

/**
 * GetPage Actioncreator
 * @param  {int} id IPROX ItmIdt
 */
export const getApplicationPageAction = (id) =>
  composeAction(pageAction(id), pageRelationsOnlyCallbacks);

export const pageOmitRelations = Object.freeze({ omitRelations: true });
export const pageOmitSelection = Object.freeze({ omitSelection: true });
export const pageOmitRecently = Object.freeze({ omitRecently: true });
export const pageOmitProcess = Object.freeze({ omitProcess: true });
export const pageOmitLandscape = Object.freeze({ omitLandscape: true });
export const pageOmitDefinition = Object.freeze({ omitDefinition: true });
export const pageOmitStructure = Object.freeze({ omitStructure: true });
export const pageOmitPresentation = Object.freeze({ omitPresentation: true });
export const pageOmitVersions = Object.freeze({ omitVersions: true });
export const pageOmitRaamwerk = Object.freeze({ omitRaamwerk: true });
export const pageOmitAll = Object.freeze({
  ...pageOmitRelations,
  ...pageOmitSelection,
  ...pageOmitRecently,
  ...pageOmitProcess,
  ...pageOmitLandscape,
  ...pageOmitDefinition,
  ...pageOmitStructure,
  ...pageOmitPresentation,
  ...pageOmitVersions,
  ...pageOmitRaamwerk,
});

export const pageIncludeRelations = Object.freeze({ omitRelations: false });

/**
 * GetPage Actioncreator
 * @param  {int} rawId IPROX ItmIdt
 * @param  {object} options ommission options
 */
export const getPageAction = (rawId, options = empty.object) => {
  const id = parseId(rawId);

  if (id <= 0) {
    return noopAction;
  }

  return composeAction(
    pageAction(id),
    options.omitRelations ? empty.object : pageRelationsCallbacks,
    options.omitSelection ? empty.object : pageSelectionCallbacks,
    options.omitRecently ? empty.object : pageRecentlyCallbacks,
    options.omitProcess ? empty.object : processCallbacks,
    options.omitLandscape ? empty.object : landscapeCallbacks,
    options.omitDefinition ? empty.object : definitionCallbacks,
    options.omitPresentation ? empty.object : pagePresentationCallbacks,
    options.omitVersions ? empty.object : pageVersionsCallbacks,
    options.omitRaamwerk ? empty.object : raamwerkCallbacks
  );
};

const toVariant = pick([
  "environmentId",
  "variantId",
  "key",
  "siteId",
  "alias",
]);
const toSaar = pick([
  "environmentId",
  "variantId",
  "key",
  "path",
  "siteId",
  "root",
  "home",
  "alias",
  "properties",
]);

export const updateMiniSaarAction = (payload) => (dispatch, getState) => {
  const saar = toSaar(payload);
  const {
    session: { miniSaar: oldSaar } = empty.object,
    form: {
      landscapeDirty: { dirty: landscapeDirty = false },
    },
  } = getState();
  if (isEqual(toSaar(oldSaar), saar)) {
    return;
  }

  const variant = toVariant(saar);
  const oldVariant = JSON.parse(sessionStorage.getItem("variant"));
  const changed = !isEqual(oldVariant, variant);
  const minisaarAction = composeAction({
    group: "MINISAAR",
    fire: identity,
    args: [saar],
    extra: { changed },
    callbackAfter: (payload, dispatch) => {
      sessionStorage.setItem("variant", JSON.stringify(variant));
      const { pathname } = hashHistory.getCurrentLocation();
      const target = `/${saar.path}`;
      if (pathname.indexOf(`${target}/`) !== 0) {
        dispatch(redirectAction(saar.home));
      }

      dispatch(requireNavigationAction());
      dispatch(requireOverzichtAction());
      dispatch(requireRecentAction());
      dispatch(requireSelectionListsAction());
      dispatch(requirePagetypeListAction());
      dispatch(requireRelationTypesAction());
    },
  });
  dispatch(landscapeDirty ? leaveAction(minisaarAction) : minisaarAction);
};

export const requireSettingsAction = () =>
  composeAction({
    group: "SETTINGS",
    fire: getSettings,
    cachekey: "settings",
  });

export const mediaWidgetAction = (id, width) =>
  composeAction({
    group: "MEDIAWIDGET",
    fire: getMediaWidget,
    args: [id, width],
    key: "id",
    cachekey: "mediaWidgets",
  });

export const koppelingFields = (itemId) =>
  composeAction({
    group: "KOPPELING_FIELDS",
    fire: (itemId) => getKoppelingFields(itemId),
    args: [itemId],
    key: "id",
  });

export const oneDriveFileInfoAction = (fieldId, oneDriveFiles) =>
  composeAction({
    group: "ONEDRIVE_INFO",
    fire: (_, oneDriveFiles) => getOneDriveFileInfo(oneDriveFiles),
    args: [fieldId, oneDriveFiles],
    key: "id",
  });

export const oneDriveFileInfoResetAction = () => ({
  type: "ONEDRIVE_INFO_CLEAR",
});

export const exportToWordAction = (itemIds) =>
  composeAction({
    group: "EXPORTWORD",
    fire: exportWord,
    args: [{ itemIds }],
    callbackSuccess: (payload) => {
      FileSaver.saveAs(
        payload,
        `Saar-${moment().format("YYYYMMYY[T]HHmm")}.docx`
      );
    },
  });

export const bulkcopyAction = (itemIds) => (dispatch) => {
  dispatch(
    addStage({
      message:
        "De geselecteerde items zullen met onderlinge relaties naar de huidige Saar gekopieerd worden.",
      action: bulkcopy.require(itemIds, {
        onSuccess: () =>
          dispatch(
            snackbarShowAction(
              "De items worden in de komende minuten gekopieerd. Ga naar het dashboard en ververs om het resultaat te zien.",
              persistentMultiLine
            )
          ),
        onFail: () =>
          dispatch(
            snackbarShowAction(
              "Er is iets mis, de items worden niet gekopieerd.",
              singleLine
            )
          ),
      }),
    })
  );
};
