import { AsyncSingleValue } from "async-lifecycle-saga";
import cytoscape from "cytoscape";
import { flow, get } from "lodash/fp";
import { Action } from "redux";

export interface MiniSaar {
  alias: string;
  name: string;
  variantId: number;
  path: string;
  siteId: number;
  home: string;
}

export interface HomeLink {
  itemId: number;
  structureId: number;
}

export interface Navigation {
  homeLink?: HomeLink;
}

export interface ContentData {
  id: number;
  names: string[];
}

export type WithClusterId = { clusterId?: number; parentClusterId?: number };

export type ClusterData = ContentData & {
  iproxType: "cluster";
} & {
  [key: string]: IproxContent;
} & WithClusterId;

export type ClustersData = {
  iproxType: "clusters";
  clusters: ClusterData[];
  names: string[];
};

export type FieldDefinition = {
  id: number;
  dataTypeCode: number;
  name: string;
  explanation?: string;
  required?: boolean;
};

export type EmptyFieldData = {
  iproxType: "field";
  isEmpty: true;
  definition: FieldDefinition;
  value: null;
  id: number;
  names: string[];
};

export type LinkValue = { linkId: number; clusterId?: number };
export type AddressValue = { label?: string; address: string };

export type FieldDataValue =
  | LinkValue
  | AddressValue
  | SelectionValue
  | SelectionValue[]
  | RaamwerkValue
  | string
  | number
  | boolean
  | null;

export type NonEmptyFieldData = {
  iproxType: "field";
  isEmpty: false;
  definition: FieldDefinition;
  value: FieldDataValue;
  id: number;
  names: string[];
};

export type FieldData = EmptyFieldData | NonEmptyFieldData;

export type FieldsData = {
  iproxType: "fields";
  fields: FieldData[];
};

export type IproxContent = ClusterData | ClustersData | FieldData | FieldsData;

export type PageContent = Record<string, IproxContent> & {
  title: string;
  pagetype: string;
};

export interface PageData {
  id: number;
  page: PageContent;
  lastPublished: string;
  slug: string;
}

export interface RelationData {
  id: number;
  relations: Relation[];
}

export interface RelationTypes {
  id: number;
  types: RelationType[];
}

export interface StructureModel {
  id: number;
  name: string;
  root: number;
  writeAccess: boolean;
}

export interface AddLegoblok {
  structures: StructureModel[];
  siteId: number;
}

const _emptyArray: any[] = [];
export const emptyArray = <T>(): T[] => _emptyArray;

const _emptyObject: any = {};
export const emptyObject = <T>(): T => _emptyObject;

export interface StoreData {
  addLegoblok: AddLegoblok;
  navigation: Navigation;
  pages: Record<number, PageData>;
  relations: RelationData[];
  relationTypes: RelationTypes;
}

export interface StoreSession {
  miniSaar?: MiniSaar;
}

export type BiebClusterRelation = {
  vanPagClsIdt: number;
  narPagClsIdt: number;
};

export type BiebRelatedPagetypeMapping = Record<
  number,
  Record<number, BiebClusterRelation[]>
>;

export interface StoreSagas {
  sisenseLogin: AsyncSingleValue<SisenseTokenOutput>;
  biebPagetypeMapping: AsyncSingleValue<BiebRelatedPagetypeMapping>;
}

export interface StoreUi {
  snackbar: {
    open: boolean;
    message?: string;
    options?: {
      singleLine?: boolean;
      persistent?: boolean;
      autoHideDuration?: number;
    };
  };
}

export interface ContentAction {
  $type: string;
  itemId: number;
  fieldId?: number;
  sourceFieldId?: number;
  clusterId?: number;
  parentId?: number;
  relationIds?: number[];
  relations?: Partial<RelTab>[];
  relationTypeGroup?: string;
  value: string;
}

export interface BiebUpdateDialogType {
  open: boolean;
  busy?: boolean;
  error?: boolean;
  biebItemId?: number;
  localItemId?: number;
  hasUpdate?: boolean;
}

export interface StoreForm {
  biebUpdateDialog: BiebUpdateDialogType;

  page: Record<
    number,
    {
      busy?: boolean;
      dirty: boolean;
      edit: boolean;
      edited: boolean;
      error?: boolean;
      version: number;
      staged: ContentAction[];
    }
  >;
}

export interface StoreRoot {
  data: StoreData;
  session: StoreSession;
  sagas: StoreSagas;
  ui: StoreUi;
  form: StoreForm;
}

export interface SelectionValue {
  selectionId: number;
  value: string;
  alias?: string;
}

export interface RaamwerkItem {
  item: SelectionValue;
  meta: SelectionValue;
}

export interface RaamwerkValue {
  items: RaamwerkItem[];
}

export interface RaamwerkTarget {
  itmIdt: number;
  lbl: string;
  bieb: boolean;
}

export interface RaamwerkAction {
  type: "ADD" | "REMOVE";
  payload: RaamwerkItem;
}

interface RevertField {
  itemId: number;
  fieldId: number;
}
interface RevertCluster {
  itemId: number;
  clusterId: number;
  name: string;
}

export type RevertType = RevertField | RevertCluster;

type FieldUpdate = (update: {
  itemId: number;
  clusterId: number;
  name: string;
  value?: string;
  values?: string[];
  revert: RevertType;
}) => void;

export interface RelTab {
  relIdt: number;
  itmRelTypIdt: number;
  vanItmIdt: number;
  vanPagClsIdt?: number;
  narItmIdt: number;
  narPagClsIdt?: number;
  metItmIdt?: number;
  metPagClsIdt?: number;
}

export type PageActions = {
  pageId: number;
  busy: boolean;
  dirty: boolean;
  edit: boolean;
  staged: { $type: string }[];
  version: number;
  edited?: boolean;
  $name?: string;

  dispatch: (action: Action<string>) => void;
  add(type: string, payload?: object, restage?: boolean): void;
  save(keepOpen?: boolean): void;
  requireClusters(cluster: {
    clusterId: number;
    name: string;
  }): Promise<unknown>;
  clusterAdd(cluster: {
    clusterId: number;
    name: string;
    choiceName?: string;
  }): Promise<unknown>;
  clusterAddRequiredClusterAlreadyPresent(cluster: {
    clusterId: number;
    name: string;
    choiceName?: string;
  }): void;
  fieldAdd: FieldUpdate;
  fieldRevert: FieldUpdate;
  stashCluster(): void;
  clusterOrder(cluster: {
    clusterId?: number;
    name: string;
    clusterIds: number[];
  }): void;
  clusterSetRelations(
    payload: {
      clusterId: number;
      relations: Partial<RelTab>[];
      relationTypeGroup: string;
    },
    clean: boolean
  ): void;
};

const patchPageActions = (
  pageActions: PageActions,
  patch: Partial<PageActions>,
  $name: string
): PageActions => ({ ...pageActions, ...patch, $name });

export const readOnlyPageActions = {
  dispatch: () => {},
  pageId: 9999999,
  busy: false,
  dirty: false,
  edit: false,
  edited: false,
  version: 0,
} as Partial<PageActions> as PageActions;

export const editPageActions = patchPageActions(
  readOnlyPageActions,
  { edit: true },
  "editPageActions"
);

export const titlePageActions = (titleUpdate: (value: string) => void) =>
  patchPageActions(
    readOnlyPageActions,
    {
      dirty: true,
      edit: true,
      fieldAdd: flow(get("value"), titleUpdate),
      fieldRevert: flow(get("value"), titleUpdate),
    },
    "titlePageActions"
  );

export const fieldPageActions = (
  fieldAdd: FieldUpdate,
  fieldRevert: FieldUpdate
) =>
  patchPageActions(
    readOnlyPageActions,
    {
      dirty: true,
      edit: true,
      fieldAdd,
      fieldRevert,
    },
    "fieldPageActions"
  );

export interface Field<TDefinition, TValue> {
  definition: TDefinition;
  value?: TValue;
}

export interface Definition {
  explanation?: string;
}

export interface FieldContentValue<TValue> {
  value: TValue;
  string?: string;
}

export interface FieldProps<TDefinition extends Definition, TValue> {
  value?: TValue;
  setValue: (content: FieldContentValue<TValue>) => void;
  pageActions?: PageActions;
  field: Field<TDefinition, TValue>;
}

export interface RaamwerkDefinition extends Definition {
  selectionListId: number;
}

export interface SelectionListItem {
  id: number;
  alias?: string;
  value: string;
  disabled?: boolean;
  items?: SelectionListItem[];
}

export interface SelectionList {
  id: number;
  alias?: string;
  value: string;
  items: SelectionListItem[];
}

export type SiteLink = {
  itemId: number;
  structureId: number;
  type: string;
  label: string;
  slug: string;
  variantId?: number;
  pageClusterId?: number;
  pageClusterLabel?: string;
  status?: SelectionListItem;
  validity?: SelectionListItem;
};

export interface ExternLink {
  id?: number;
  url: string;
  label: string;
}

export type StatusLink = {
  id: string;
  status: string;
  link?: SiteLink;
  externLink?: ExternLink;
  leftItemId?: number;
  remark?: string;
};

export type RaamwerkTreeItem = {
  id: number;
  alias?: string;
  value: string;
  items: RaamwerkTreeItem[];
  links: StatusLink[];
  emptyItemCount?: number;
  statusLinkCount: { [key: string]: number };
  disabled: boolean;
  remark?: string;
};

export type Pagetype = {
  id: number;
  name: string;
  alias: string;
  allowed: number;
};

export interface StructureItem {
  itemId: number;
  label: string;
  pagetype: string;
  slug: string;
  children?: StructureItem[];
  status?: SelectionListItem;
}

export interface RelationCluster {
  pageClusterId: number;
  pageClusterLabel: string;
  mutation?: number;
}

export type RelationItem = SiteLink & { status?: SelectionListItem };

export interface RelationListItem {
  id: number;
  ids?: number[];
  item: RelationItem;
  pageClusterId?: number;
  relation: Relation;
  using: SiteLink[];
  mutation?: number;
}

export interface BiebMappingLine {
  van: number;
  nar: number;
}

export interface Relation {
  id: number;
  relationTypeId: number;
  side: RelationSide;
  show?: number;
  left: SiteLink;
  right: SiteLink;
  using: SiteLink[];
  modificationDateTime: string;
  clusters?: BiebMappingLine[];
}

export interface RelationGroup {
  alias: string;
  links: { relation: Relation }[];
}

interface RelationTypeSide {
  label: string;
  labelSpec: string;
  pagetype: string;
  clusterId?: number;
}

export interface RelationType {
  id: number;
  alias: string;
  canExist: boolean;
  mayAdd: boolean;
  mayAddToBieb: boolean;
  mayDelete: boolean;
  needsCluster: boolean;
  hierarchically: boolean;
  symmetrically: boolean;
  pagetype: string;
  metaPagetype: string;
  forceMiniSaar: boolean;
  clusterFirst: boolean;
  rule: number;
  scope: number;
  showSide: RelationTypeShowSide;
  showClusters: RelationTypeShowClusters;
  position: RelationsPosition;
  displayLabel?: string;
  left?: RelationTypeSide;
  right?: RelationTypeSide;
  using?: RelationTypeSide;
}

export type RelationsPosition = 0 | 1 | 2 | 3 | 4;

export type RelationSide = 0 | 1 | 2 | 3;

export type RelationTypeShowSide = 0 | 1;

export const ShowSide = {
  default: 0,
  clusterSecundair: 1,
};

export type RelationTypeShowClusters = 0 | 1;

export const ShowClusters = {
  default: 0,
  rollup: 1,
};

export type Validity = "definitief" | "concept" | "archief";

export interface CopyItemModel {
  sourceItemId: number;
  label: string;
  withHierarchyRelations: boolean;
  structureId?: number;
}

export interface SisenseTokenOutput {
  success: boolean;
  result?: SisenseToken;
  errorMessage?: string;
}

export interface SisenseToken {
  token: string;
  redirect: string;
}

export type PlainValue = string | null;

export interface InternetValue {
  address: string;
}

export type KoppelingClusterMapping = Record<number, number[]>;

export enum IndexViewVisibility {
  hidden = 0,
  private = 1,
  public = 2,
  global = 3,
}

export interface IndexViewColumnGroup {
  name: string;
  fields: IndexViewColumn[];
  relations: IndexViewColumn[];
}

export interface IndexViewColumn {
  name: string;
  alias: string;
}

export interface ViewBase {
  name: string;
  visibility: IndexViewVisibility;
}

export interface IndexViewData extends ViewBase {
  filter: string;
  columnFilters: Record<string, string>;
  queryString: string;
  order: {
    property?: number | string;
    descending: boolean;
  };
  columns: IndexViewColumn[];
}

export interface ViewId {
  id: number;
  own: boolean;
}

export type IndexView = IndexViewData & ViewId;

export type PresentHidden = "showAll" | "omitArchive" | "omitAll";

export interface LandscapeViewData extends ViewBase {
  default: boolean;
  pagetype: string;
  nodes: cytoscape.NodeDefinition[];
  presentHidden: PresentHidden;
}

export type LandscapeView = LandscapeViewData & ViewId;

export interface LinkModel {
  saarItemId: number;
  biebItemId: number;
}

export interface ItemReferenceMap {
  [id: string]: SiteLink[];
}
