import { composeAction } from "async-lifecycle";
import empty from "empty";
import FileSaver from "file-saver";

import { exportData, exportOverzicht, sendMail } from "../api";
import { fetchVariantJSON } from "../api/fetch";
import { emptyArray } from "../business/models";
import { invalidateRecentlyCreatedAction } from "./dashboard";
import {
  getPageAction,
  invalidateOverzichtAction,
  requireOverzichtAction,
  requireSettingsAction,
} from "./data";
import { invalidatePage } from "./page";
import { invalidateRelations } from "./relations.invalidate";
import { persistentSingleLine, snackbarShowAction } from "./ui";

export const emptyErrorAction = () => (dispatch) => {
  dispatch({
    type: "EMPTY_ERROR",
  });
};

export const sendMailAction = (message) =>
  composeAction({
    group: "SEND_MAIL",
    fire: (message) => sendMail(message),
    args: [message],
  });

export const clearForm = (type) => ({
  type: "FORM_CLEAR",
  payload: { type },
});

/**
 * Exports the supplied data
 */
export const exportDataAction = (title, columnNames, data) =>
  composeAction({
    group: "EXPORT",
    fire: (title, columnNames, data) => exportData(title, columnNames, data),
    args: [title, columnNames, data],
    callbackSuccess: (payload) => FileSaver.saveAs(payload, `${title}.xlsx`),
  });

/**
 * Exports the specified overzicht
 */
export const exportOverzichtAction = (overzichtModel) =>
  composeAction({
    group: "EXPORT",
    fire: ({ title, pagetype, columns, all }) =>
      exportOverzicht({ title, pagetype, columns, all }),
    args: [overzichtModel],
    callbackSuccess: (payload, dispatch) => {
      dispatch(invalidateOverzichtAction());
      dispatch(requireOverzichtAction());
      FileSaver.saveAs(payload, `${overzichtModel.title}.xlsx`);
    },
  });

export const toggleImageLightboxAction = (show, image) => ({
  type: "IMAGE_LIGHTBOX_TOGGLE",
  payload: { show, image },
});

export const setOverzichtAction = (payload) => ({
  type: "FORM_OVERZICHT_SET",
  payload,
});

export const resetOverzichtAction = () => ({
  type: "FORM_OVERZICHT_CLEAR",
});

export const logError = (error) => ({
  type: "ERROR_LOG",
  payload: { error },
});

export const logAlert = (friendly) => ({
  type: "ERROR_LOG",
  payload: { error: { friendly, status: 100 } },
});

const jobWatch = {};
const jobActive = (area) =>
  area in jobWatch ? { awaiting: jobWatch[area] } : undefined;

const backgroundJobStatusFailed = 3;

const startJobWatcher = (
  area,
  onCancel,
  onFinish,
  onFail,
  onComplete,
  onUpdate,
  checkOnly = false
) => {
  if (jobActive(area)) {
    // Do not run multiple watchers for the same area
    onCancel();
    return;
  }

  jobWatch[area] = !checkOnly;

  const handler = () => {
    fetchVariantJSON("cms/job/finishedjobs").then(
      ({ finishedJobs: { $values: jobs }, unfinishedCount = 0 }) => {
        let hasFailedJobs = false;
        try {
          if (jobs.length > 0) {
            onFinish(jobs);
          }

          const failedJobIds = jobs
            .filter((job) => job.status === backgroundJobStatusFailed)
            .map(({ id }) => id);
          hasFailedJobs = failedJobIds.length > 0;
          if (hasFailedJobs) {
            onFail(failedJobIds);
          }
        } catch (e) {
          console.error(e);
        }

        if (unfinishedCount === 0) {
          delete jobWatch[area];
          window.clearInterval(jobWatcher);

          if (jobs.length > 0) {
            onComplete(hasFailedJobs);
          }
        } else if (!jobWatch[area]) {
          jobWatch[area] = true;
          onUpdate();
        }
      }
    );
  };

  // Start the watcher
  const jobWatcher = window.setInterval(handler, 10_000);
  if (!checkOnly) {
    window.setTimeout(handler, 1);
  }
};

export const useJobActive = () => jobActive("job")?.awaiting;

export const getCurrentPageAction = () => {
  const [, id] = /\/(\d+)\//.exec(window.location.hash) || [];
  return id ? getPageAction(Number(id)) : { type: "NOOP" };
};

const finishJob = (dispatch, job) => {
  const { sourceItems = emptyArray(), resultItems = sourceItems } = job;
  for (const { itemId } of sourceItems.concat(resultItems)) {
    dispatch(invalidatePage(itemId));
    dispatch(invalidateRelations(itemId));
  }
  dispatch(invalidateRecentlyCreatedAction());
};

const biebJob = {
  init: empty.func,
  finish: finishJob,
};

const contentUpdateJob = {
  init: (dispatch, checkOnly) => {
    if (!checkOnly) {
      dispatch(
        snackbarShowAction(
          "De pagina wordt bijgewerkt in de achtergrond",
          persistentSingleLine
        )
      );
    }
  },
  finish: finishJob,
};

const statusUpdateJob = {
  init: (dispatch, checkOnly) => {
    if (!checkOnly) {
      dispatch(
        snackbarShowAction(
          "De status wordt bijgewerkt in de achtergrond",
          persistentSingleLine
        )
      );
    }
  },
  finish: finishJob,
};

const jobs = {
  BiebUseJob: biebJob,
  BulkCopyJob: biebJob,
  ContentUpdateJob: contentUpdateJob,
  StatusUpdateJob: statusUpdateJob,
};

export const startJobsWatcher = (dispatch, checkOnly, trigger) => {
  jobs[trigger]?.init(dispatch, checkOnly);

  startJobWatcher(
    "job",

    empty.func,

    // Finish action => invalidate all affected data
    (jobs) => {
      for (const job of jobs) {
        jobs[job.type]?.finish(dispatch, job);
      }
    },

    // Fail action
    (ids = emptyArray()) => {
      const id = ids.join("+");
      dispatch(
        snackbarShowAction(
          `Achtergrondtaak kon niet (volledig) voltooid worden, job id = #${id}`,
          persistentSingleLine
        )
      );
    },

    // Complete action
    (failed) => {
      if (!failed) {
        dispatch(
          snackbarShowAction(
            "Achtergrondtaak is voltooid",
            persistentSingleLine
          )
        );
      }

      dispatch(getCurrentPageAction());
    },

    // Update action
    () => {
      dispatch({ type: "SETTINGS_INVALIDATE" });
      dispatch(requireSettingsAction());
    },

    checkOnly
  );
};
