import {
  DropDownMenu,
  FlatButton,
  IconButton,
  IconMenu,
  MenuItem,
} from "material-ui";
import Opacity from "material-ui/svg-icons/action/opacity";
import Visibility from "material-ui/svg-icons/action/visibility";
import VisibilityOff from "material-ui/svg-icons/action/visibility-off";
import Save from "material-ui/svg-icons/content/save";

import {
  LandscapeView,
  PresentHidden,
  SiteLink,
} from "../../../business/models";
import CenterFocusWeak from "../../../svg/CenterFocusWeak";
import Fullscreen from "../../../svg/Fullscreen";
import Stacks from "../../../svg/Stacks";
import { ItemLink } from "../../relation.itemlink";
import DownloadMenu from "./downloadMenu";
import ToolMenu, { ToolMenuProps } from "./toolMenu";

export interface ToolbarProps {
  landscapeItem?: SiteLink;

  view?: LandscapeView;

  views?: LandscapeView[];

  presentHidden: PresentHidden;

  setPresentHidden(presentHidden: PresentHidden): void;

  onViewSelect(view?: LandscapeView): void;

  onFullscreen(): void;

  saveCurrentView?(): void;
}

interface ToolbarEvents extends ToolMenuProps {
  createCompoundNode(): void;

  fitViewport(): void;

  download(viewport?: boolean, transparent?: boolean): void;

  layout: cytoscape.LayoutOptions;

  onChangeLayout(layout: cytoscape.LayoutOptions): void;
}

const Toolbar = ({
  landscapeItem,
  view,
  views,
  presentHidden,
  setPresentHidden,
  onViewSelect,
  onFullscreen,
  saveCurrentView,
  createCompoundNode,
  fitViewport,
  download,
  layout,
  onChangeLayout,
  ...toolMenuProps
}: ToolbarProps & ToolbarEvents) => (
  <div style={{ display: "flex", gap: 2, justifyContent: "space-between" }}>
    <div>
      {landscapeItem && (
        <FlatButton
          style={{ marginTop: "8px", marginRight: "auto" }}
          label={`Toon ${landscapeItem.label}`}
          containerElement={<ItemLink item={landscapeItem} />}
        />
      )}
    </div>
    <div>
      <DropDownMenu
        value={view?.id ?? ""}
        style={{ height: 40, overflow: "hidden" }}
      >
        {!views?.some((v) => v.default) && (
          <MenuItem
            primaryText="Standaard"
            onClick={() => onViewSelect()}
            checked={!view}
            value=""
          />
        )}
        {views?.map((v) => (
          <MenuItem
            key={v.id}
            value={v.id}
            primaryText={v.name}
            onClick={() => onViewSelect(v)}
            checked={view?.id === v.id}
          />
        ))}
      </DropDownMenu>
      <ToolMenu {...toolMenuProps} />
      <IconButton
        title="Opslaan"
        onClick={saveCurrentView}
        disabled={!saveCurrentView}
      >
        <Save />
      </IconButton>
      <IconButton title="Groepering maken" onClick={createCompoundNode}>
        <Stacks />
      </IconButton>
      <DropDownMenu
        value={layout.name}
        style={{ height: 40, overflow: "hidden" }}
      >
        <MenuItem
          primaryText="Origineel"
          onClick={() => onChangeLayout({ name: "preset" })}
          checked={layout.name === "preset"}
          value="preset"
          style={{ fontVariant: "italic" }}
        />
        <MenuItem
          primaryText="Breed"
          onClick={() => onChangeLayout({ name: "breadthfirst" })}
          checked={layout.name === "breadthfirst"}
          value="breadthfirst"
        />
        <MenuItem
          primaryText="Concentrisch"
          onClick={() => onChangeLayout({ name: "concentric" })}
          checked={layout.name === "concentric"}
          value="concentric"
        />
        <MenuItem
          primaryText="Grid"
          onClick={() => onChangeLayout({ name: "grid" })}
          checked={layout.name === "grid"}
          value="grid"
        />
      </DropDownMenu>
      <span title="Wijzig zichtbaarheid van uitgefilterde items">
        <IconMenu
          value={presentHidden}
          onChange={(_event: never, value: PresentHidden) =>
            setPresentHidden(value)
          }
          iconButtonElement={
            presentHidden === "showAll" ? (
              <Visibility />
            ) : presentHidden === "omitArchive" ? (
              <Opacity />
            ) : (
              <VisibilityOff />
            )
          }
          style={{ marginRight: 10 }}
        >
          <MenuItem
            leftIcon={<Visibility />}
            value="showAll"
            primaryText="Uitgefilterd vervagen"
          />
          <MenuItem
            leftIcon={<Opacity />}
            value="omitArchive"
            primaryText="Uitgefilterd vervagen; archief verbergen"
          />
          <MenuItem
            leftIcon={<VisibilityOff />}
            value="omitAll"
            primaryText="Uitgefilterd verbergen"
          />
        </IconMenu>
      </span>
      <IconButton title="Venster opvullen" onClick={fitViewport}>
        <CenterFocusWeak />
      </IconButton>
      <IconButton title="Volledig scherm" onClick={onFullscreen}>
        <Fullscreen />
      </IconButton>
      <DownloadMenu download={download} />
    </div>
  </div>
);

export default Toolbar;
