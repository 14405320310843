import { flatten, map } from "lodash/fp";
import Checkbox from "material-ui/Checkbox";
import Divider from "material-ui/Divider";
import FlatButton from "material-ui/FlatButton";
import MenuItem from "material-ui/MenuItem";
import SelectField from "material-ui/SelectField";
import Delete from "material-ui/svg-icons/action/delete";
import TextField from "material-ui/TextField";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import {
  IndexViewColumn,
  IndexViewColumnGroup,
  IndexViewData,
  IndexViewVisibility,
  emptyArray,
} from "../../business/models";
import Dialog from "../../containers/dialog";
import { flown } from "../../lodash";
import { columnsForIndex } from "../../sagas";
import { userSelector } from "../../selectors";

const ColumnSelect = ({
  label,
  allColumns,
  value,
  onChange,
}: {
  label: string;
  allColumns: IndexViewColumnGroup[];
  value: IndexViewColumn;
  onChange: (value: IndexViewColumn | undefined) => void;
}) => (
  <SelectField
    floatingLabelText={label}
    floatingLabelFixed
    value={value}
    onChange={(_, __, v) => onChange(v)}
  >
    {value.alias !== "new" && (
      <MenuItem
        key="clear"
        primaryText="Verwijderen"
        leftIcon={<Delete />}
        disabled={!value}
      />
    )}
    {allColumns.map((c) => {
      const group = (
        <MenuItem
          key={`group-${c.name}`}
          disabled
          style={{ fontVariant: "small-caps" }}
          primaryText={c.name}
          title={c.name}
        />
      );
      const fields = c.fields.map((a) => (
        <MenuItem
          key={`item-${a.alias}`}
          value={a}
          primaryText={a.name}
          title={a.name}
        />
      ));
      const divider =
        c.fields.length > 0 && c.relations.length > 0
          ? [<Divider key={`divider-${c.name}`} />]
          : emptyArray();
      const relations = c.relations.map((a) => (
        <MenuItem
          key={`item-${a.alias}`}
          value={a}
          primaryText={a.name}
          title={a.name}
        />
      ));

      return [group, ...fields, ...divider, ...relations];
    })}
  </SelectField>
);

interface Props {
  view: IndexViewData;
  onCancel: () => void;
  onConfirm: (view: IndexViewData) => void;
}

const maxColumns = 8;
const IndexViewDialog = ({ view, onCancel, onConfirm }: Props) => {
  const mayGlobal = useSelector(userSelector)?.iznet === "Write";
  const allColumns = useSelector(columnsForIndex.select).value ?? emptyArray();

  const [name, setName] = useState(view.name);
  const [visibility, setVisibility] = useState(view.visibility);
  const [columns, setColumns] = useState(view.columns ?? emptyArray());
  const valid =
    name !== "" && columns.length > 0 && columns.length <= maxColumns;

  useEffect(() => {
    const flat: IndexViewColumn[] = flown(
      allColumns,
      map((a: IndexViewColumnGroup) => a.fields.concat(a.relations)),
      flatten
    );

    setColumns((columns) =>
      columns.map((c) => flat.find((a) => a.alias === c.alias) ?? c)
    );
  }, [allColumns]);

  return (
    <Dialog
      title="Weergave opslaan"
      open
      autoScrollBodyContent
      actions={[
        <FlatButton
          key="close"
          secondary
          label="Annuleren"
          onClick={onCancel}
        />,
        <FlatButton
          primary
          key="save"
          label="Opslaan"
          onClick={() => onConfirm({ ...view, name, visibility, columns })}
          disabled={!valid}
        />,
      ]}
    >
      <div className="indexview-form">
        <TextField
          name="name"
          floatingLabelText="Naam weergave"
          floatingLabelFixed
          required
          value={name}
          onChange={(_, v) => setName(v)}
        />
        <div>
          <div style={{ display: "inline-block" }}>
            <Checkbox
              name="visibility"
              label="Gedeeld"
              checked={visibility > IndexViewVisibility.private}
              onCheck={(_, v) =>
                setVisibility(
                  v ? IndexViewVisibility.public : IndexViewVisibility.private
                )
              }
            />
          </div>
          {mayGlobal && (
            <div
              style={{ display: "inline-block", marginLeft: 24, width: 200 }}
            >
              <Checkbox
                name="visibility"
                label="over alle Saars"
                checked={visibility === IndexViewVisibility.global}
                onCheck={(_, v) =>
                  setVisibility(
                    v ? IndexViewVisibility.global : IndexViewVisibility.public
                  )
                }
              />
            </div>
          )}
        </div>
        {columns.map((c, i) => (
          <ColumnSelect
            key={i}
            allColumns={allColumns}
            label={`Kolom ${i + 1}`}
            value={c}
            onChange={(v) =>
              setColumns(
                columns
                  .slice(0, i)
                  .concat(v ?? [])
                  .concat(columns.slice(i + 1, columns.length))
              )
            }
          />
        ))}
        {columns.length < maxColumns && (
          <ColumnSelect
            value={{ name: "New", alias: "new" }}
            allColumns={allColumns}
            label={`Kolom ${columns.length + 1}`}
            onChange={(v) => setColumns(columns.concat(v ?? []))}
          />
        )}
      </div>
    </Dialog>
  );
};

export default IndexViewDialog;
