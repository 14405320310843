import Checkbox from "material-ui/Checkbox";
import DropDownMenu from "material-ui/DropDownMenu";
import FlatButton from "material-ui/FlatButton";
import MenuItem from "material-ui/MenuItem";
import { useState } from "react";
import { useSelector } from "react-redux";

import { emptyArray } from "../../business/models";
import DialogWithStoreContext from "../../containers/dialog";
import FloatingLabelText from "../material/FloatingLabelText";

interface Props {
  open: boolean;
  itemId: number;
  itemIds: number[];
  onCancel(): void;
  onConfirm(validity: string, itemIds: number[]): void;
}

interface Document {
  fields: Record<string, string>;
  lookup: Record<string, string[]>;
}

const BulkUpdateDialog = ({
  open,
  onCancel,
  itemId,
  itemIds,
  onConfirm,
}: Props) => {
  const [selected, setSelected] = useState(itemIds);
  const [validity, setValidity] = useState("definitief");
  const documents = useSelector(
    (state: {
      data: {
        selection: {
          [index: number]: {
            documents: Document[];
          };
        };
      };
    }) => state.data.selection[itemId]?.documents ?? emptyArray()
  );
  const emptyDocument = { fields: {}, lookup: {} } as Document;
  const document = (itemId: string) =>
    documents.find((doc) => doc.fields.itemId === itemId) ?? emptyDocument;
  return (
    <DialogWithStoreContext
      title="Status van pagina wijzigen"
      open={open}
      actions={[
        <FlatButton secondary label="Annuleren" onClick={onCancel} />,
        <FlatButton
          primary
          label="OK"
          onClick={() => onConfirm(validity, itemIds)}
        />,
      ]}
    >
      <FloatingLabelText text="Status van pagina" />
      <DropDownMenu
        value={validity}
        onChange={(_event: never, _index: never, value: string) =>
          setValidity(value)
        }
      >
        <MenuItem value="concept" primaryText="Concept" />
        <MenuItem value="definitief" primaryText="Definitief" />
        <MenuItem value="archief" primaryText="Archief" />
      </DropDownMenu>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          paddingRight: 20,
        }}
      >
        <FloatingLabelText text="Selectie" />
        <FloatingLabelText text="Huidige status" />
      </div>
      {itemIds.map((itemId) => {
        const { fields, lookup } = document(itemId.toString());
        return (
          <Checkbox
            key={itemId}
            label={
              <span
                style={{
                  display: "inline-flex",
                  justifyContent: "space-between",
                  width: "100%",
                }}
              >
                <span>{fields["label"]}</span>
                <span>{lookup["status"]?.[0]}</span>
              </span>
            }
            checked={selected.includes(itemId)}
            onCheck={(_event: never, checked: boolean) => {
              setSelected((prevSelected) =>
                checked
                  ? [...prevSelected, itemId]
                  : prevSelected.filter((id) => id !== itemId)
              );
            }}
          />
        );
      })}
    </DialogWithStoreContext>
  );
};

export default BulkUpdateDialog;
